import { SiteLocale, SupportedLanguage, SupportedLocale, SupportedUrl } from './translations.cnst'

export interface LocaleObject {
  language: SupportedLanguage
  locale: SupportedLocale
  name: string
  url: SupportedUrl
  siteLocale: SiteLocale
}

export const locales: LocaleObject[] = [
  {
    language: SupportedLanguage.en,
    locale: SupportedLocale.enUS,
    name: 'English',
    url: SupportedUrl.enUS,
    siteLocale: SiteLocale.enUS,
  },
]
