import { brandPurple } from '@src/cnst/colors.cnst'
import { Button, ButtonThemes } from '@src/components/button/Button.component'
import { Logo } from '@src/components/logo/Logo.component'
import { SignupButton } from '@src/components/signup-button/SignupButton.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import styles from './HeaderUncoupled.module.scss'

interface HeaderUncoupledProps {
  signupButtonText?: string
  // the bottom parameters are for the newlanding page structures
  ctaUrl?: string
  cta?: string
  theme?: 'dark' | 'altering' | 'shimmerGradient'
  position?: 'sticky' | 'fixed'
}

export function HeaderUncoupled({
  signupButtonText,
  cta,
  ctaUrl,
  theme = 'altering',
  position = 'fixed',
}: HeaderUncoupledProps): ReactNode {
  const isMobile = useBreakpoint().width < Breakpoints.bpMedium
  const discountCode = useAppSelector(state => state.discountCode.code)
  const [code, setCode] = useState('')

  useEffect(() => {
    setCode(discountCode || sessionStorage.getItem('discountCode') || '')
  }, [discountCode])

  const scrollYPosition = useScrollYPosition()

  const [scrolled, setScrolled] = useState(false)
  const [applyDarkTheme, setApplyDarkTheme] = useState(false)
  const [applyLightTheme, setApplyLightTheme] = useState(true)
  const [hasCode, setHasCode] = useState(!!code)
  const [logoColor, setLogoColor] = useState(brandPurple)
  const [signUpTheme, setSignUpTheme] = useState<ButtonThemes>('brandPurple')

  // I had to add useEffect because of this problem https://github.com/facebook/react/issues/13260
  // React 16 className reconciliation problem - classNames not updated on render despite changing.
  useEffect(() => {
    if (scrolled || hasCode || theme === 'dark' || theme === 'shimmerGradient') {
      setApplyDarkTheme(true)
      setApplyLightTheme(false)
    } else if (!hasCode && !scrolled && theme === 'altering') {
      setApplyLightTheme(true)
      setApplyDarkTheme(false)
    }
  }, [code, hasCode, scrolled])

  useEffect(() => {
    if (scrollYPosition > 0) {
      setScrolled(true)
      setLogoColor('#fff')

      if (theme === 'shimmerGradient') {
        setSignUpTheme('shimmerGradient')
      } else {
        setSignUpTheme('brandBlue2023')
      }
    } else if (scrollYPosition < 1) {
      setScrolled(false)
      if (!hasCode) {
        setLogoColor(brandPurple)
        setSignUpTheme(theme === 'shimmerGradient' ? 'shimmerGradient' : 'brandPurple')
      }
    }
  }, [hasCode, scrollYPosition, theme])

  useEffect(() => {
    if (code || theme === 'dark' || theme === 'shimmerGradient') {
      setHasCode(true)
      setLogoColor('#fff')
      setSignUpTheme(theme === 'shimmerGradient' ? 'shimmerGradient' : 'brandBlue2023')
    } else {
      setHasCode(false)
    }
  }, [discountCode, code, theme])

  const container = classNames(styles.HeaderUncoupled__container, {
    [styles.HeaderUncoupled__container___sticky]: position === 'sticky',
    [styles.HeaderUncoupled__container___fixed]: position === 'fixed',
    [styles.HeaderUncoupled__layoutDark]: applyDarkTheme,
    [styles.HeaderUncoupled__layoutLight]: applyLightTheme,
  })

  const layout = classNames({
    [styles.HeaderUncoupled__layoutPersistentDiscount]: hasCode,
    [styles.HeaderUncoupled__layout]: !hasCode,
    [styles.HeaderUncoupled__layoutDark]: applyDarkTheme,
    [styles.HeaderUncoupled__layoutLight]: applyLightTheme,
  })

  return (
    <header>
      <div className={container}>
        <div className={layout}>
          <div className={styles.HeaderUncoupled__logo}>
            <Logo color={logoColor} uid="header-uncoupled-logo" />
          </div>
          <div className={styles.HeaderUncoupled__signup}>
            {ctaUrl ? (
              <Button size={isMobile ? 'small' : 'regular'} theme={signUpTheme} href={ctaUrl}>
                {cta}
              </Button>
            ) : (
              <SignupButton size={isMobile ? 'small' : 'regular'} theme={signUpTheme}>
                {signupButtonText || cta}
              </SignupButton>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
